.tableRow,
.tableHeaderRow {
    text-align: left;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.tableRow:hover {
    background-color: rgba(0, 0, 0, 0.07);
}

.tableRowSelected {
    background-color: rgba(0, 0, 0, 0.04);
}

.tableRowClickable {
    cursor: pointer;
}

.tableCell {
    padding: 14px 40px 14px 16px;
    font-size: 0.875rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    vertical-align: inherit;
}
.tableCell:last-child {
    padding-right: 16px;
}

.tableHeaderCell {
    color: rgba(0, 0, 0, 0.54);
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1.3125rem;
}

.checkboxCell {
    width: 48px;
    padding: 4px;
}

.tablePadding24 tr > td:first-child {
    padding-left: 24px;
}
.tablePadding24 tr > td:last-child {
    padding-right: 24px;
}

.tablePadding31 tr > td:first-child, .tablePadding31 th:first-child {
    padding-left: 31px;
}
.tablePadding31 tr > td:last-child, .tablePadding31 th:last-child {
    padding-right: 31px;
}
