.SortableList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  list-style: none;
}
.SortableListColumns {
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 0;
  list-style: none;
  cursor: pointer;
}
